import React from "react";
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo"></div>
          <div className="social">电话：400-8879-121</div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>掌心宝贝 带您迈入 AI家园共育新时代</h1>
            <p>智能连接老师、家长、园长</p>
          </div>
          <a href="https://www.ancda.com">
            <div className="cta">进入官网</div>
          </a>
        </div>
        <div className="footer">
          <div className="copyright">
            Copyright © 2022 深圳市云领天下科技有限公司
          </div>
          <div>
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502004903"
              target="_blank"
            >
              粤公网安备 44030502004903号
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="http://beian.miit.gov.cn/" target="_blank">
              粤ICP备13070746号
            </a>
          </div>
        </div>
      </div>
    );
  };
}

export default App;
